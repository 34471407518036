<template>
    <div class="w-100 pr-3 mb-3 flex-grow-1 flex-shrink-1">
        <h5>Лог коммуникаций</h5>
        <b-button @click.prevent="openTicketModal">
            Создать тикет
        </b-button>
        <div id="historyTickets" class="my-4">
            <b-table
                :items="historyTickets.items"
                :fields="historyTicketsFields"
                :busy.sync="isBusy"
                show-empty
            >
                <template #table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle" />
                        <strong>Загрузка данных...</strong>
                    </div>
                </template>
                <template #cell(num)="data">
                    {{ data.index + 1 + (pagination.currentPage - 1) * pagination.perPage }}
                </template>
                <template #empty>
                    Нет сохраненных тикетов
                </template>
            </b-table>
        </div>
        <b-row>
            <b-col>
                <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItems"
                    :current-page="pagination.currentPage"
                />
            </b-col>
            <b-col class="d-flex align-items-center">
                <b-button class="ml-auto" @click.prevent="downloadCsv">
                    Выгрузить в csv
                </b-button>
            </b-col>
        </b-row>
        <b-modal
            id="ticketModal"
            hide-footer
            @close="onReset"
        >
            <template #modal-title>
                <div>
                    {{ borrower.surname }} {{ borrower.name }} {{ borrower.patronymic ? borrower.patronymic : "" }}
                </div>
                <div>
                    Количество дней просрочки: {{ loanData.delayDays }}
                </div>
                <div>
                    Текущая сумма задолженности: {{ dedt.toLocaleString() }}
                </div>
                <div>
                    Сумма к пролонгации: {{ sumProlong }}
                </div>
            </template>
            <b-overlay 
                :show="request" 
                rounded="sm"
            >
                <b-form
                    novalidate 
                    :class="{'was-validated': wasValidated}" 
                    autocomplete="off" 
                    @submit="onSubmitTicket"
                >
                    <b-form-group
                        label="Канал"
                    >
                        <b-form-select
                            id="channel"
                            v-model="communication.channel"
                            :options="ticketParams.channels"
                            value-field="key"
                            text-field="name"
                            required
                        />
                    </b-form-group>
                    <b-form-group 
                        v-if="requiresMedium"
                        label="Номер телефона"
                    >
                        <b-form-select
                            v-model="phone"
                            :options="ticketParams.phones"
                            required
                        />
                    </b-form-group>
                    <b-form-group
                        label="Успешность"
                    >
                        <b-form-select
                            v-model="successfully"
                            :options="ticketParams.resultGroups"
                            :disabled="communication.channel === null"
                            value-field="key"
                            text-field="name"
                            required
                        />
                    </b-form-group>
                    <b-form-group 
                        v-if="requiresContact"
                        label="Лицо"
                    >
                        <b-form-select
                            v-model="communication.contactType"
                            :options="ticketParams.contactTypes"
                            value-field="key"
                            text-field="name"
                            required
                        />
                    </b-form-group>
                    <b-form-group
                        label="Результат"
                    >
                        <b-form-select
                            v-model="communication.result"
                            :options="ticketParams.results"
                            :disabled="successfully === null || successfully === undefined"
                            value-field="key"
                            text-field="name"
                            required
                        />
                    </b-form-group>
                    <template v-if="requiresPayPromise">
                        <b-form-group
                            label="Дата планируемого платежа"
                        >
                            <b-form-datepicker
                                v-model="payPromise.dueAt"
                                :min="minDatePay" 
                                :max="maxDatePay" 
                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                locale="ru"
                                placeholder="Выберите дату"
                                :state="stateData(payPromise.dueAt)"
                                required
                            />
                        </b-form-group>
                        <b-form-group
                            label="Сумма планируемая к оплате"
                        >
                            <b-form-input
                                v-model="payPromise.sum"
                                type="number"
                                step="0.01"
                                required
                            />
                        </b-form-group>
                    </template>
                    <b-form-group
                        v-if="requiredCallBack"
                        label="Дата и время перезвона"
                    >
                        <b-row>                      
                            <b-col cols="6">
                                <b-form-datepicker
                                    v-model="callBack.date"
                                    :min="minDatePay" 
                                    :max="maxDatePay" 
                                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                    locale="ru"
                                    :state="stateData(callBack.date)"
                                    placeholder="Выберите дату"
                                    required
                                />
                            </b-col>
                            <b-col cols="6">
                                <b-form-timepicker
                                    v-model="callBack.time"
                                    locale="ru"
                                    :state="stateTime"
                                    required
                                />
                            </b-col>
                        </b-row>
                    </b-form-group>
                    <b-form-group
                        v-if="requiresNonPaymentReason"
                        label="Причина неоплаты"
                    >
                        <b-form-select
                            v-model="communication.nonPaymentReason"
                            :options="ticketParams.nonPaymentReasons"
                            :disabled="communication.result === null"
                            value-field="key"
                            text-field="name"
                            required
                        />
                    </b-form-group>
                    <b-form-group 
                        label="Комментарий" 
                        description="не более 300 символов"
                    >
                        <b-form-textarea
                            v-model="communication.comment"
                            :formatter="sliceComment"
                            no-resize
                        />
                    </b-form-group>
                    <b-button :disabled="request" type="submit">
                        Сохранить
                    </b-button>
                </b-form>
            </b-overlay>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "Ticket",
    props: {
        loanData: {
            type: Object,
            default: () => {},
        },
        communicationParams: {
            type: Object,
            default: () => {},
        },
        borrower: {
            type: Object,
            default: () => {
                return {
                    surname: "",
                    name: "",
                    patronymic: "",
                };
            },
        },
    },
    data() {
        return {
            communication: {
                channel: null,
                contactType: null,
                result: null,
                nonPaymentReason: null,
                comment: null,
            },
            successfully: null,
            phone: null,
            payPromise: {
                dueAt: null,
                sum: null,
            },
            callBack: {
                date: null,
                time: null,
            },
            historyTickets: [],
            historyTicketsFields: [
                {
                    key: "num",
                    label: "№",
                },
                {
                    key: "createdAt",
                    label: "Дата и время",
                    formatter(value) {
                        return window.formatDatetime(value);
                    },
                },
                {
                    key: "userName",
                    label: "ФИО сотрудника",
                },
                {
                    key: "daysPastDue",
                    label: "Количество дней",
                },
                {
                    key: "debt",
                    label: "Остаток задолженности",
                },
                {
                    key: "mediumValue",
                    label: "Номер телефона",
                    formatter(value) {
                        return value ? value : "-";
                    },
                },
                {
                    key: "contactType",
                    label: "Лицо",
                    formatter(value) {
                        return value ? value : "-";
                    },
                },
                {
                    key: "channel",
                    label: "Канал",
                },
                {
                    key: "resultGroup",
                    label: "Успешность/неуспешность",
                },
                {
                    key: "result",
                    label: "Результат",
                },
                {
                    key: "nonPaymentReason",
                    label: "Причина неоплаты",
                    formatter(value) {
                        return value ? value : "-";
                    },
                },
                {
                    key: "payPromiseDueAt",
                    label: "Дата планируемого платежа",
                    formatter(value) {
                        return value ? window.formatDatetime(value, "DD.MM.YYYY HH:mm:ss", "Etc/GMT") : "-";
                    },
                },
                {
                    key: "payPromiseSum",
                    label: "Сумма планируемая к оплате",
                    formatter(value) {
                        return value ? value.toLocaleString() : "-";
                    },
                },
                {
                    key: "callBackAt",
                    label: "Дата и время перезвона",
                    formatter(value) {
                        return value ? window.formatDatetime(value, "DD.MM.YYYY HH:mm:ss", "Etc/GMT") : "-";
                    },
                },
                {
                    key: "comment",
                    label: "Комментарий",
                    formatter(value) {
                        return value ? value : "-";
                    },
                },
            ],
            isCall: false,
            requiresMedium: false,
            requiresContact: false,
            requiresNonPaymentReason: false,
            requiredCallBack: false,
            requiresPayPromise: false,
            errors: {},
            wasValidated: false,
            pagination: {
                currentPage: 1,
                perPage: 10,
                totalItems: 10,
            },
            request: false,
            isBusy: false,
        };
    },
    computed: {
        ticketParams() {
            return {...this.communicationParams};
        },
        minDatePay() {
            const now = new Date();
            return now;
        },
        maxDatePay() {
            const now = new Date();
            now.setMonth(now.getMonth() + 1);
            return now;
        },
        // общая сумма задолжности
        dedt() {
            return  (this.loanData.accountMain + this.loanData.accountPercent+this.loanData.accountPenalty+this.loanData.accountAdditional);
        },
        // сумма продления
        sumProlong() {
            return (this.loanData.accountPercent + this.loanData.accountPenalty).toLocaleString()
        },
        stateTime() {
            if (this.wasValidated && this.callBack.time === null) {
                return false;
            }
            if (this.wasValidated && this.callBack.time !== null) {
                return true;
            }

            return null;
        },
    },
    watch: {
        "communication.channel"() {
            // номер телефона
            this.requiresMedium = this.ticketParams.channels
                .find((item) => item.key === this.communication.channel)?.requiresMedium || false;
            // учесть звонок, наполнение 
            this.isCall = this.ticketParams.channels
                .find((item) => item.key === this.communication.channel)?.isCall || false;

            this.successfully = null;
            this.phone = null;

            // выводить только соотвествующие пункты по параметру
            this.ticketParams.resultGroups = this.communicationParams.resultGroups.filter(item => {
                // звонок
                if (this.isCall) {
                    return ["communication_established", "communication_unsuccessful"].includes(item.key)
                }
                // сообщение
                return ["communication_established", "message_sent"].includes(item.key);
            });

        },
        successfully() {
            this.requiresContact = this.ticketParams.resultGroups
                .find((item) => item.key === this.successfully)?.requiresContact || false;
            this.requiresNonPaymentReason = this.ticketParams.resultGroups
                .find((item) => item.key === this.successfully)?.requiresNonPaymentReason || false;

            this.communication.contactType = null;
            this.communication.result = null;

            // список результатов соответственно полю Успешность 
            this.ticketParams.results = this.communicationParams.results.filter((item) => item.groupId === this.successfully);
        },
        "communication.contactType"() {
            this.communication.result = null;
        },
        "communication.result"() {
            this.requiredCallBack = false;
            this.requiresPayPromise = false;
            this.callBack = {
                date: null,
                time: null,
            };
            this.payPromise = {
                dueAt: null,
                sum: null,
            };

            this.requiredCallBack = this.ticketParams.results
                .find((item) => item.key === this.communication.result)?.requiredCallBack || false;
            this.requiresPayPromise = this.ticketParams.results
                .find((item) => item.key === this.communication.result)?.requiresPayPromise || false;

            this.communication.nonPaymentReason = null;
            // обещает оплатить - заполняем суммой задолжности
            if (this.requiresPayPromise) {
                this.payPromise.sum = this.dedt;
            }
        },
        "pagination.currentPage"() {
            this.getHistory();
        }
    },
    mounted() {
        this.getHistory();
    },
    methods: {
        getHistory() {
            this.isBusy = true;
            this.jsonRpc("admin.communication.getCommunications", {
                loanId: parseInt(this.$route.params.id),
                page: this.pagination.currentPage,
                pageSize: this.pagination.perPage,
            }).then((res) => {
                this.historyTickets = res;
                this.pagination.totalItems = res.totalCount;
            }).finally(() => {
                this.isBusy = false;
            });
        },
        openTicketModal() {
            this.$bvModal.show("ticketModal");
            return false;
        },
        sliceComment(val) {
            return val.slice(0, 300);
        },
        stateData(val) {
            if (this.wasValidated && val === null) {
                return false;
            }
            if (this.wasValidated && val !== null) {
                return true;
            }

            return null;
        },
        onReset() {
            this.communication = {
                channel: null,
                contactType: null,
                result: null,
                nonPaymentReason: null,
                comment: null,
            };
            this.successfully = null,
            this.phone = null,
            this.payPromise = {
                dueAt: null,
                sum: null,
            };
            this.callBack = {
                date: null,
                time: null,
            };

            this.requiresMedium = false;
            this.requiresContact = false;
            this.requiresPayPromise = false;
            this.requiredCallBack = false;
            this.requiresNonPaymentReason = false;

            this.wasValidated = false;
        },
        onSubmitTicket(event) {
            event.preventDefault();

            if (this.request) {
                return;
            }

            this.wasValidated = true;

            let form = event.target;
            // проверка валидации
            if (form.checkValidity() === false) {
                event.stopPropagation();
                return;
            }

            this.request = true;

            let medium = null;
            // номер телефона, так на будещее, если будем еще почту подтягивать или типа того
            if (this.requiresMedium) {
                medium = {
                    type: "phone",
                    value: this.phone,
                };
            }

            const payPromise = this.requiresPayPromise ? this.payPromise : null;
            // обещание заплатить
            if (payPromise !== null) {
                payPromise.dueAt = payPromise.dueAt + "T00:00:00"
                    + "+00:00";
                payPromise.sum = +payPromise.sum;
            }
            // перезвонить
            let callBack = this.requiredCallBack ? {} : null;

            if (this.requiredCallBack) {
                callBack.callBackAt = this.callBack.date + "T" 
                    + this.callBack.time
                    + "+00:00";
            }

            const params = {
                communication: {
                    ...this.communication,
                    medium,
                    loanId: parseInt(this.$route.params.id),
                },
                callBack,
                payPromise,
            };
            console.log("params: ", params);

            this.jsonRpc("admin.communication.addCommunication", params)
                .then(() => {
                    this.$alertify.success("Запись добавлена");
                    this.$bvModal.hide("ticketModal");

                    if (this.isCall) {
                        this.$emit("ticket-fixed-phone", this.phone);
                        this.$bvModal.show("callModal");
                    }

                    this.onReset();
                })
                .then(() => {
                    this.getHistory();
                })
                .catch(async ({error}) => {
                    if (this.$formValidation.isValidationRpcResponse(error)) {
                        this.errors = this.$formValidation.parse(error.data);
                    }
                })
                .finally(async () => {
                    this.wasValidated = false;
                    this.request = false;
                });
        },
        downloadCsv() {
            this.jsonRpc("admin.communication.exportCommunications", {
                loanId: parseInt(this.$route.params.id),
            }).then((res) => {
                const blob = new Blob([res], { type: "text/csv" });
                
                const a = document.createElement("a");
                a.href = URL.createObjectURL(blob);
                a.target = "_blank";
                a.download = `tickets_${parseInt(this.$route.params.id)}_.csv`;
                a.click();
            }).catch((err) => {
                console.log("err", err);
            })

        },
    },
}
</script>

<style>
#ticketModal {
  pointer-events: none;
}

#ticketModal .modal-title div:first-child {
  font-size: 24px;
}

#ticketModal .modal-title div:not(:first-child) {
  font-size: 16px;
}

#historyTickets {
  overflow-x: auto;
}
</style>